import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import apiService from '../../middleware/apiService.js'

const instance = apiService(axios)

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    instance.interceptors.request.use(              
      config => {
        // ** Get token from localStorage
        let accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          accessToken = JSON.parse(accessToken)
        
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    instance.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            }).catch(() => {
              // ** Remove user, accessToken & refreshToken from localStorage
              localStorage.removeItem('userData')
              localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
              localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
              return Promise.reject(error)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return instance.post('/auth/signin', ...args)
    // return axios.post(this.jwtConfig.loginEndpoint, ...args)    
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    let refreshToken = this.getRefreshToken()
    if (refreshToken) {
      refreshToken = JSON.parse(refreshToken)

      return instance.post('/auth/refresh-token', { refreshToken })
    } else {
      return Promise.reject('No token found')
    }
    // return axios.post(this.jwtConfig.refreshEndpoint, {    
    //   refreshToken: this.getRefreshToken()
    // })
  }

  sendResetPasswordLink(...args) {
    return instance.post('/users/reset-password-link', ...args)
  }

  resetPassword(...args) {
    return instance.post('/users/reset-password', ...args)
  }

  selfRegister(...args) {
    return instance.post('/users/self-register', ...args)
  }

  verifyEmail(...args) {
    return instance.post('/users/verify-email', ...args)
  }

  // User apis
  getAllUserData() {
    return instance.post('/users/get-all')
  }

  getUserData(...args) {
    return instance.post('/users/get-all', ...args)
  }

  getUser(...args) {
    return instance.post('/users/get-one', ...args)
  }

  addUser(...args) {
    return instance.post('/users/register', ...args)
  }

  deleteUser(...args) {
    return instance.post('/users/delete', ...args)
  }

  updateUser(...args) {
    return instance.post('/users/update', ...args)
  }

  userStatistics(...args) {
    return instance.post('/users/statistics', ...args)
  }

  changePassword(...args) {
    return instance.post('/users/change-password', ...args)
  }

  compareCompany(...args) {
    return instance.post('/users/compare-company', ...args)
  }

  // Company apis
  getAllCompaniesData() {
    return instance.post('/company/get-all')
  }

  getCompanyData(...args) {
    return instance.post('/company/get-all', ...args)
  }

  addCompany(...args) {
    return instance.post('/company/add', ...args)
  }

  addCompanyOverView(...args) {
    return instance.post('/company/add-overview', ...args)
  }

  addCompanyCapabilities(...args) {
    return instance.post('/company/add-capabilities', ...args)
  }

  addPastPerformanceRerference(...args) {
    return instance.post('/company/add-past-reference', ...args)
  }

  deleteCompany(...args) {
    return instance.post('/company/delete', ...args)
  }

  getCompany(...args) {
    return instance.post('/company/get-one', ...args)
  }

  updateCompany(...args) {
    return instance.post('/company/update', ...args)
  }
  
  getFilteredCompanyList(...args) {
    return instance.post('/company/get-filtered', ...args)
  }

  // Project apis
  getAllProjectsData() {
    return instance.post('/project/get-all')
  }

  getProjectData(...args) {
    return instance.post('/project/get-all', ...args)
  }

  addProject(...args) {
    return instance.post('/project/add', ...args)
  }

  deleteProject(...args) {
    return instance.post('/project/delete', ...args)
  }

  getProject(...args) {
    return instance.post('/project/get-one', ...args)
  }

  updateProject(...args) {
    return instance.post('/project/update', ...args)
  }

  // Report apis
  getAllReportsData() {
    return instance.post('/report/get-all')
  }

  getReportData(...args) {
    return instance.post('/report/get-all', ...args)
  }

  addReport(...args) {
    return instance.post('/report/add', ...args)
  }

  deleteReport(...args) {
    return instance.post('/report/delete', ...args)
  }

  getReport(...args) {
    return instance.post('/report/get-one', ...args)
  }

  updateReport(...args) {
    return instance.post('/report/update', ...args)
  }

  // Project sharing apis
  getUsersForNewProject(...args) {
    return instance.post('/project/get-all-user-for-new-project', ...args)
  }

  getUsersForExistingProject(...args) {
    return instance.post('/project/get-all-user-for-old-project', ...args)
  }

  getProjectsForClient(...args) {
    return instance.post('/project/get-projects-for-client', ...args)
  }

  // Country apis
  getAllCountries() {
    return instance.post('/country/get-all')
  }

  // File upload/download apis
  uploadFile(args) {
    return instance.post('/upload-file', args)
  }

  // powerbi account apis
  getAllPowerBIAccountData() {
    return instance.post('/powerbi-account/get-all')
  }

  getPowerBIAccountData(...args) {
    return instance.post('/powerbi-account/get-all', ...args)
  }

  addPowerBIAccount(...args) {
    return instance.post('/powerbi-account/add', ...args)
  }

  deletePowerBIAccount(...args) {
    return instance.post('/powerbi-account/delete', ...args)
  }

  getPowerBIAccount(...args) {
    return instance.post('/powerbi-account/get-one', ...args)
  }

  updatePowerBIAccount(...args) {
    return instance.post('/powerbi-account/update', ...args)
  }

  // pipeline apis
  getAllPipelinesData() {
    return instance.post('/pipeline/get-all')
  }

  getPipelineData(...args) {
    return instance.post('/pipeline/get-all', ...args)
  }

  addPipeline(...args) {
    return instance.post('/pipeline/add', ...args)
  }

  deletePipeline(...args) {
    return instance.post('/pipeline/delete', ...args)
  }

  getPipeline(...args) {
    return instance.post('/pipeline/get-one', ...args)
  }

  updatePipeline(...args) {
    return instance.post('/pipeline/update', ...args)
  }

  getPipelineCommonSettings(...args) {
    return instance.post('/pipeline/get-common-settings', ...args)
  }
  
  updatePipelineCommonSettings(...args) {
    return instance.post('/pipeline/update-common-settings', ...args)
  }

  // budget forecasting api
  getAllBudgetForecastingResources(...args) {
    return instance.post('/resource/budget-forecasting/get-all', ...args)
  }

  addBudgetForecastingResourceCategory(...args) {
    return instance.post('/resource/budget-forecasting/add-category', ...args)
  }

  updateBudgetForecastingResourceCategory(...args) {
    return instance.post('/resource/budget-forecasting/update-category', ...args)
  }

  deleteBudgetForecastingResourceCategory(...args) {
    return instance.post('/resource/budget-forecasting/delete-category', ...args)
  }

  addBudgetForecastingResourceItem(...args) {
    return instance.post('/resource/budget-forecasting/add-item', ...args)
  }

  updateBudgetForecastingResourceItem(...args) {
    return instance.post('/resource/budget-forecasting/update-item', ...args)
  }

  deleteBudgetForecastingResourceItem(...args) {
    return instance.post('/resource/budget-forecasting/delete-item', ...args)
  }

  // small business api
  getAllSmallBusinessResources(...args) {
    return instance.post('/resource/small-business/get-all', ...args)
  }

  addSmallBusinessResourceCategory(...args) {
    return instance.post('/resource/small-business/add-category', ...args)
  }

  updateSmallBusinessResourceCategory(...args) {
    return instance.post('/resource/small-business/update-category', ...args)
  }

  deleteSmallBusinessResourceCategory(...args) {
    return instance.post('/resource/small-business/delete-category', ...args)
  }

  addSmallBusinessResourceItem(...args) {
    return instance.post('/resource/small-business/add-item', ...args)
  }

  updateSmallBusinessResourceItem(...args) {
    return instance.post('/resource/small-business/update-item', ...args)
  }

  deleteSmallBusinessResourceItem(...args) {
    return instance.post('/resource/small-business/delete-item', ...args)
  }

  // general settings apis
  getGeneralSettings(...args) {
    return instance.post('/general-settings/get', ...args)
  }
  
  updateGeneralSettings(...args) {
    return instance.post('/general-settings/update', ...args)
  }

  getAdvertiseSettings(...args) {
    return instance.post('/advertise/get', ...args)
  }
  
  updateAdvertiseSettings(...args) {
    return instance.post('/advertise/update', ...args)
  }

  // redshift query apis
  getReportFromRedshift(...args) {
    return instance.post('/redshift/query', ...args)
  }

  // calendar event apis
  getAllCalendarEventData() {
    return instance.post('/calendar/get-all')
  }

  getCalendarEventData(...args) {
    return instance.post('/calendar/get-all', ...args)
  }

  addCalendarEvent(...args) {
    return instance.post('/calendar/add', ...args)
  }

  deleteCalendarEvent(...args) {
    return instance.post('/calendar/delete', ...args)
  }

  getCalendarEvent(...args) {
    return instance.post('/calendar/get-one', ...args)
  }

  updateCalendarEvent(...args) {
    return instance.post('/calendar/update', ...args)
  }    

  // dashboard widget template event apis
  getAllWidgetTemplateData() {
    return instance.post('/widget-template/get-all')
  }

  getWidgetTemplateData(...args) {
    return instance.post('/widget-template/get-all', ...args)
  }

  addWidgetTemplate(...args) {
    return instance.post('/widget-template/add', ...args)
  }

  deleteWidgetTemplate(...args) {
    return instance.post('/widget-template/delete', ...args)
  }

  getWidgetTemplate(...args) {
    return instance.post('/widget-template/get-one', ...args)
  }

  updateWidgetTemplate(...args) {
    return instance.post('/widget-template/update', ...args)
  } 

  // custom dashboard widget management apis
  getAllUserWidgets(...args) {
    return instance.post('/custom-dashboard-widgets/get-all', ...args)
  }

  updateAllUserWidgets(...args) {
    return instance.post('/custom-dashboard-widgets/update-all', ...args)
  }

  // custom dashboard management api
  getAllUserDashboardData() {
    return instance.post('/user-dashboard/get-all')
  }

  getUserDashboardData(...args) {
    return instance.post('/user-dashboard/get-all', ...args)
  }

  addUserDashboard(...args) {
    return instance.post('/user-dashboard/add', ...args)
  }

  deleteUserDashboard(...args) {
    return instance.post('/user-dashboard/delete', ...args)
  }

  getUserDashboard(...args) {
    return instance.post('/user-dashboard/get-one', ...args)
  }

  updateUserDashboard(...args) {
    return instance.post('/user-dashboard/update', ...args)
  }

  // user profile management api
  getUserProfile(...args) {
    return instance.post('/user-profile/get-one', ...args)
  }

  saveUserProfile(...args) {
    return instance.post('/user-profile/save', ...args)
  }

  // company group management api
  getAllCompanyGroupData() {
    return instance.post('/group/get-all')
  }

  getCompanyGroupData(...args) {
    return instance.post('/group/get-all', ...args)
  }

  addCompanyGroup(...args) {
    return instance.post('/group/add', ...args)
  }

  deleteCompanyGroup(...args) {
    return instance.post('/group/delete', ...args)
  }

  getCompanyGroup(...args) {
    return instance.post('/group/get-one', ...args)
  }

  updateCompanyGroup(...args) {
    return instance.post('/group/update', ...args)
  }   

  // Team management api
  getAllTeamData() {
    return instance.post('/team/get-all')
  }

  getTeamData(...args) {
    return instance.post('/team/get-all', ...args)
  }

  addTeam(...args) {
    return instance.post('/team/add', ...args)
  }

  deleteTeam(...args) {
    return instance.post('/team/delete', ...args)
  }

  getTeam(...args) {
    return instance.post('/team/get-one', ...args)
  }

  updateTeam(...args) {
    return instance.post('/team/update', ...args)
  } 

  // Naics api
  getAllNaicsData() {
    return instance.post('/naics/get-all')
  }

  // Company profile management api
  getCompanyProfile(...args) {
    return instance.post('/company-profile/get-one', ...args)
  }

  saveCompanyProfile(...args) {
    return instance.post('/company-profile/save', ...args)
  }

  // Bard-ai api
  createBardAISession(...args) {
    return instance.post('/bard-ai/add-session', ...args)
  }

  initBardAISession(...args) {
    return instance.post('/bard-ai/init-session', ...args)
  }

  getAllBardAISessions(...args) {
    return instance.post('/bard-ai/get-all-session', ...args)
  }

  getBardAISession(...args) {
    return instance.post('/bard-ai/get-one-session', ...args)
  }

  getAllBardAIHistories(...args) {
    return instance.post('/bard-ai/get-all-histories', ...args)
  }

  updateFeedback(...arg) {
    return instance.post('/bard-ai/update-feedback', ...arg)
  }

  updateBardAISession(...args) {
    return instance.post('/bard-ai/update-session', ...args)
  }

  deleteBardAISession(...args) {
    return instance.post('/bard-ai/delete-session', ...args)
  }

  askToBardAI(...args) {
    return instance.post('/bard-ai/ask', ...args)
  }

  // ML apis
  getRecommendations(...args) {
    return instance.post('/ml-ai/recommendations', ...args)
  }

  askToNLQ(...args) {
    return instance.post('/ml-ai/ask-nlq', ...args)
  }

  // Tracking apis
  getAllTrackingData() {
    return instance.post('/tracking/get-all')
  }

  getTrackingData(...args) {
    return instance.post('/tracking/get-all', ...args)
  }

  addTracking(...args) {
    return instance.post('/tracking/add', ...args)
  }

  deleteTracking(...args) {
    return instance.post('/tracking/delete', ...args)
  }

  deleteAllTracking(...args) {
    return instance.post('/tracking/delete-all', ...args)
  }

  getTracking(...args) {
    return instance.post('/tracking/get-one', ...args)
  }

  updateTracking(...args) {
    return instance.post('/tracking/update', ...args)
  }

  // Opportunity api
  getDraftProposal(...args) {
    return instance.post('/open-ai/get-proposal', ...args)
  }

  // Proposal Settings api
  addProposalSetting(...args) {
    return instance.post('/proposal_settings/add', ...args)
  }

  deleteProposalSetting(...args) {
    return instance.post('/proposal_settings/delete', ...args)
  }

  getProposalSetting(...args) {
    return instance.post('/proposal_settings/get-one', ...args)
  }

  updateProposalSetting(...args) {
    return instance.post('/proposal_settings/update', ...args)
  }

  // Mattermost apis
  chatAutoLogin(...args) {
    return instance.post('/mm-chat/auto-signin', ...args)
  }

  chatAutoLogout(...args) {
    return instance.post('/mm-chat/auto-signout', ...args)
  }

  // OpenKM apis
  getFolderDocuments(...args) {
    return instance.post('/openkm-dms/get-folder-documents', ...args)
  }

  getDocumentNotes(...args) {
    return instance.post('/openkm-dms/get-document-notes', ...args)
  }

  addNoteToDocument(...args) {
    return instance.post('/openkm-dms/add-note', ...args)
  }

  deleteNoteFromDocument(...args) {
    return instance.post('/openkm-dms/delete-note', ...args)
  }

  uploadDocumentToDMS(...args) {
    return instance.post('/openkm-dms/upload-document', ...args)
  }

  deleteDocumentFromDMS(...args) {
    return instance.post('/openkm-dms/delete-document', ...args)
  }

  updateDocumentToDMS(...args) {
    return instance.post('/openkm-dms/update-document', ...args)
  }

  downloadDocumentFromDMS(...args) {
    return instance.post('/openkm-dms/download-document', ...args, {responseType: 'blob'})
  }

  getCompanyFolders(...args) {
    return instance.post('/openkm-dms/get-all-company-folders', ...args)
  }

  // Onboarding apis
  getAllOnboardingCompaniesData() {
    return instance.post('/onboarding/get-all')
  }

  getOnboardingCompanyData(...args) {
    return instance.post('/onboarding/get-all', ...args)
  }

  addOnboardingCompany(...args) {
    return instance.post('/onboarding/add', ...args)
  }

  deleteOnboardingCompany(...args) {
    return instance.post('/onboarding/delete', ...args)
  }

  rejectOnboardingCompany(...args) {
    return instance.post('/onboarding/reject', ...args)
  }

  getOnboardingCompany(...args) {
    return instance.post('/onboarding/get-one', ...args)
  }

  updateOnboardingCompany(...args) {
    return instance.post('/onboarding/update', ...args)
  }

  approveOnboardingCompany(...args) {
    return instance.post('/onboarding/approve', ...args)
  }

  postApproveOnboardingCompany(...args) {
    return instance.post('/onboarding/post-approve', ...args)
  }
}
