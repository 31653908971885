// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Use jwt service instead of default axios
import useJwt from '@src/auth/jwt/useJwt'

export const getAllData = createAsyncThunk('appOnboardings/getAllData', async () => {  
  const response = await useJwt.getAllOnboardingCompaniesData()
  return response.data
})

export const getData = createAsyncThunk('appOnboardings/getData', async params => {
  const response = await useJwt.getOnboardingCompanyData(params)
  return {
    params,
    data: response.data.onboardings,
    totalPages: response.data.total
  }
})

export const getOnboarding = createAsyncThunk('appOnboardings/getOnboarding', async id => {
  const response = await useJwt.getOnboardingCompany({ id })
  return response.data
})

export const addOnboarding = createAsyncThunk('appOnboardings/addOnboarding', async (onboarding, { dispatch, getState }) => {
  await useJwt.addOnboardingCompany(onboarding)  
  await dispatch(getData(getState().onboardings.params))
  await dispatch(getAllData())
  return onboarding
})

export const updateOnboarding = createAsyncThunk('appOnboardings/updateOnboarding', async (onboarding, { dispatch, getState }) => {
  await useJwt.updateOnboardingCompany(onboarding)  
  await dispatch(getData(getState().onboardings.params))
  await dispatch(getAllData())
  return onboarding
})

export const deleteOnboarding = createAsyncThunk('appOnboardings/deleteOnboarding', async (id, { dispatch, getState }) => {
  await useJwt.deleteOnboardingCompany({ id })
  await dispatch(getData(getState().onboardings.params))
  await dispatch(getAllData())
  return id
})

export const rejectOnboarding = createAsyncThunk('appOnboardings/rejectOnboarding', async (id, { dispatch, getState }) => {
  await useJwt.rejectOnboardingCompany({ id })
  await dispatch(getData(getState().onboardings.params))
  await dispatch(getAllData())
  return id
})

export const approveOnboarding = createAsyncThunk('appOnboardings/approveOnboarding', async (onboarding, { dispatch, getState }) => {
  const response = await useJwt.approveOnboardingCompany(onboarding)
  const {id, created} = response.data  
  const postApproveData = { ...onboarding, company_id: id, created }
  await useJwt.postApproveOnboardingCompany(postApproveData)  
  await dispatch(getData(getState().onboardings.params))
  await dispatch(getAllData())
  return onboarding
})

export const toggleLoading = createAsyncThunk('appOnboardings/toggleLoading', async () => {
  return true
})

export const appOnboardingsSlice = createSlice({
  name: 'appOnboardings',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedOnboarding: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
      .addCase(getOnboarding.fulfilled, (state, action) => {
        state.selectedOnboarding = action.payload
        state.loading = false
      })
      .addCase(updateOnboarding.fulfilled, (state, action) => {
        state.selectedOnboarding = {...state.selectedOnboarding, ...action.payload }
      })
      .addCase(toggleLoading.fulfilled, (state) => {
        state.loading = true
      })
      .addCase(approveOnboarding.fulfilled, (state) => {
        state.loading = false
      })
  }
})

export default appOnboardingsSlice.reducer